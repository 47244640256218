/* eslint eqeqeq: 0 */
import {
    Box,
    Button,
    Checkbox,
    FormControl,
    Grid,
    MenuItem,
    OutlinedInput,
    TextField,
    Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { MAP_API } from "../../../config/config";
import { t } from "i18next";
import api from "../../../API/Fetch_data_Api";
import { extractAddress } from "../../../util/Helper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

export const UpdateAddress = ({ selectedAddress, addressUpdate, index }) => {
    const { t } = useTranslation();

    const [type, setType] = useState("");
    const [lat, setLat] = useState("");
    const [lng, setLng] = useState("");
    const [area, setArea] = useState("");

    useEffect(() => {
        const addressInfo = localStorage.getItem("EditableAddress");
        const addresses = JSON.parse(addressInfo);

        setEditAddress(addresses.area);
        setEditPin(addresses.pincode);
        setEditMobile(addresses.mobile);
        setEditLandmark(addresses.landmark);
        setLat(addresses.lattitude);
        setLng(addresses.longitude);
    }, []);

    const mapRef = useRef(null);
    const geocoder = useRef(null);

    useEffect(() => {
        // Load Google Maps API script dynamically
        const script = document.createElement("script");
        script.src = `https://maps.googleapis.com/maps/api/js?key=${MAP_API}&libraries=places&v=weekly`;
        script.async = true;
        document.body.appendChild(script);

        script.onload = () => {
            // Initialize map and geocoder once the Google Maps API is loaded
            initMap();
            initGeocoder();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const initMap = () => {
        const map = new window.google.maps.Map(mapRef.current, {
            center: { lat: parseFloat(lat), lng: parseFloat(lng) },
            zoom: 8,
            streetViewControl: false,
        });

        // Add a marker to the map
        const marker = new window.google.maps.Marker({
            map: map,
            position: { lat: parseFloat(lat), lng: parseFloat(lng) },
            draggable: true,
        });

        map.addListener("click", (event) => {
            const lat = event.latLng.lat();
            const lng = event.latLng.lng();

            setLat(event.latLng.lat());
            setLng(event.latLng.lng());

            marker.setPosition({ lat: lat, lng: lng });
            const position = marker.getPosition();

            geocodeLatLng(position);
        });

        // Listen for marker position changes
        marker.addListener("dragend", () => {
            const position = marker.getPosition();
            geocodeLatLng(position);
        });
    };

    const initGeocoder = () => {
        // Create a geocoder instance
        geocoder.current = new window.google.maps.Geocoder();
    };

    const geocodeLatLng = (latLng) => {
        geocoder.current.geocode({ location: latLng }, (results, status) => {
            if (status === "OK" && results[0]) {
                // Handle geocoder results if needed
                const formattedAddress = results[0].formatted_address;
                setEditAddress(formattedAddress);

                const addressComponents = results[0].address_components;
                const areaComponent = addressComponents.find(
                    (component) =>
                        component.types.includes("locality") ||
                        component.types.includes("sublocality")
                );

                if (areaComponent) {
                    const areaName = areaComponent.long_name;

                    setArea(areaName);
                    // You can store or process the area name as needed
                }
            } else {
                console.log("Geocoder failed due to:", status);
            }
        });
    };

    const handleTypeChange = (e) => {
        setType(e.target.value);
    };

    const handleSubmit = (id) => {
        api
            .AddAddress(
                id,
                editMobile,
                editAddress,
                "",
                area,
                lat,
                lng,
                area,
                type,
                "92",
                "",
                "",
                "",
                "",
                editLandmark
            )
            .then((result) => {
                toast.success(result.message);
            })
            .catch((error) => console.log("error", error));
    };

    const addressInfo = localStorage.getItem("EditableAddress");
    const addresses = JSON.parse(addressInfo);

    const [editAddress, setEditAddress] = useState("");
    const [editPin, setEditPin] = useState("");
    const [editMobile, setEditMobile] = useState("");
    const [editLandmark, setEditLandmark] = useState("");

    const handleMobileChang = (e) => {
        const inputValue = e.target.value;

        const numericValue = inputValue.replace(/\D/g, '');
        // Limit the input to a maximum of 16 characters
        if (numericValue.length <= 16 || numericValue === '') {
            setEditMobile(numericValue);
        }
    };

    return (
        <div>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                    <Box>
                        <h3>{t("update_location")}</h3>
                        <div ref={mapRef} className="add-map"></div>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Box display={"flex"} flexWrap={"wrap"}>
                        <div>
                            <FormControl className="m-1-w-52" sx={{ mb: 2 }} fullWidth variant="outlined">
                                <OutlinedInput
                                    id="area"
                                    value={editAddress}
                                    placeholder="Area, Location"
                                    onChange={(e) => setEditAddress(e.target.value)}
                                />
                            </FormControl>

                            <FormControl className="m-1-w-52" sx={{ mb: 2 }} fullWidth variant="outlined">
                                <OutlinedInput
                                    id="pincode"
                                    value={editPin}
                                    placeholder="pincode"
                                    onChange={(e) => setEditPin(e.target.value)}
                                />
                            </FormControl>
                            <FormControl className="m-1-w-52" sx={{ mb: 2 }} fullWidth>
                                <OutlinedInput
                                    value={editMobile}
                                    id="mobile"
                                    placeholder="Mobile no"
                                    onChange={handleMobileChang}
                                />
                            </FormControl>
                        </div>
                        <div>
                            <TextField
                                select
                                label=""
                                id="location-type"
                                className="m-1-w-52" sx={{ mb: 2 }} fullWidth
                                placeholder="type"
                                value={type} // Use the state variable
                                onChange={handleTypeChange}
                            >
                                <MenuItem value="home">{t("home")}</MenuItem>
                                <MenuItem value="office">{t("office")}</MenuItem>
                                <MenuItem value="other">{t("other")}</MenuItem>
                            </TextField>

                            <FormControl variant="standard" className="m-1-w-52" sx={{ mb: 2 }} fullWidth>
                                <TextField
                                    value={editLandmark}
                                    id="landmark"
                                    label=""
                                    onChange={(e) => setEditLandmark(e.target.value)}
                                    placeholder="landmark"
                                />
                            </FormControl>
                        </div>
                    </Box>
                    <Box width={"52ch"}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => handleSubmit(addresses.id)}
                            className="address-btn"
                            fullWidth
                        >
                            {t("update_address")}
                        </Button>
                    </Box>
                </Grid>
            </Grid>
        </div>
    );
};

const AddressForm = () => {
    const [lat, setLat] = useState("");
    const [lng, setLng] = useState("");
    const [locationName, setLocationName] = useState("");
    const [city, setCity] = useState("");
    const [mobile, setmobile] = useState(null);
    const [appartment, setAppartment] = useState("");
    const mapRefe = useRef(null);
    const autocompleteRef = useRef(null);
    const geocoder = useRef(null);
    const [isDefault, setIsDefault] = useState(true);
    const [loading, setLoading] = useState(false);


    //load google map when page load
    useEffect(() => {
        // Load Google Maps API script dynamically
        const script = document.createElement("script");
        script.src = `https://maps.googleapis.com/maps/api/js?key=${MAP_API}&libraries=places`;
        script.async = true;
        document.body.appendChild(script);

        script.onload = () => {
            // Initialize map, autocomplete, and geocoder once the Google Maps API is loaded
            initMap();
            initAutocomplete();
            initGeocoder();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // initialization map with marker
    const initMap = () => {
        // Create a new map instance
        const map = new window.google.maps.Map(mapRefe.current, {
            center: { lat: 23.241346, lng: 69.675293 },
            zoom: 8,
            streetViewControl: false,
        });

        // Add a marker to the map
        const marker = new window.google.maps.Marker({
            map: map,
            position: { lat: 23.241346, lng: 69.675293 },
            draggable: true,
        });

        map.addListener("click", (event) => {
            const lat = event.latLng.lat();
            const lng = event.latLng.lng();

            marker.setPosition({ lat: lat, lng: lng });
            const position = marker.getPosition();
            setLat(position.lat().toFixed(6));
            setLng(position.lng().toFixed(6));
            geocodeLatLng(position);

            // Reverse geocode the dragged position to get the location name
            const geocoder = new window.google.maps.Geocoder();
            geocoder.geocode({ location: position }, (results, status) => {
                if (status === "OK" && results[0]) {

                    const address = results[0].formatted_address;
                    setAddressArea(address);
                } else {

                }
            });
        });

        // Listen for marker position changes
        marker.addListener("dragend", () => {
            const position = marker.getPosition();
            setLat(position.lat().toFixed(6));
            setLng(position.lng().toFixed(6));

            // Reverse geocode the dragged position to get the location name
            geocodeLatLng(position);
        });
    };

    //Autocomplete for when user select location in map auto filled up
    const initAutocomplete = () => {
        const autocomplete = new window.google.maps.places.Autocomplete(
            autocompleteRef.current
        );

        // Listen for place selection
        autocomplete.addListener("place_changed", () => {
            const place = autocomplete.getPlace();

            setLat(place.geometry.location.lat().toFixed(6));
            setLng(place.geometry.location.lng().toFixed(6));
        });
    };

    const initGeocoder = () => {
        // Create a geocoder instance
        geocoder.current = new window.google.maps.Geocoder();
    };

    //geocode for location detection
    const geocodeLatLng = (latLng) => {
        geocoder.current.geocode({ location: latLng }, (results, status) => {
            if (status === "OK" && results[0]) {
                // Extract the location name from the geocoder result
                const nxr = extractAddress(results[0]);

                setCity(nxr.city);
                const formattedAddress = results[0].formatted_address;
                setLocationName(formattedAddress);

            } else {
                console.log("Geocoder failed due to:", status);
            }
        });
    };

    //when user fire add address button to submit request on api
    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        handleAddAddress();
    };



    //when user fire add address button to submit request on api
    const handleAddAddress = () => {


        api
            .AddAddress(
                "",
                mobile,
                locationName,
                "",
                city,
                lat,
                lng,
                appartment,
                addressType,
                "92",
                "",
                "",
                "",
                isDefault,
                ""
            )
            .then((result) => {
                setLoading(false);
                if (result.error) {

                    for (var key of Object.keys(result.message)) {
                        if (key == "address")
                            toast.error("Address is required");
                        if (key == "city_name")
                            toast.error("City Name is required");
                        if (key == "area")
                            toast.error("Area/Appartment Name is required");
                        if (key == "mobile")
                            toast.error("Mobile Number is required");

                    }
                } else {
                    setLocationName("");
                    setAppartment("");
                    setmobile("");
                    setCity("");

                    api
                        .getAddress().then(res => {

                            setLoading(false);

                        })
                        .then(e => {
                            window.location.reload();
                        });
                }
            })
            .catch((error) => console.log("error", error));
    };

    const [addressType, setAddressType] = useState("home");
    const [addressArea, setAddressArea] = useState("");

    const area = localStorage.getItem("addLocationName");
    useEffect(() => {
        console.log(addressArea)
        setAddressArea(area);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [area]);

    const [inputValue, setInputValue] = useState("");

    useEffect(() => {
        const storedValue = localStorage.getItem("userAddress");
        console.log(inputValue)
        if (storedValue) {
            setInputValue(storedValue);
        }

        const handleStorageChange = (e) => {
            if (e.key === "userAddress") {
                setInputValue(e.newValue);
            }
        };

        window.addEventListener("storage", handleStorageChange);
        return () => {
            window.removeEventListener("storage", handleStorageChange);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const handleMobileChang = (e) => {
        const inputValue = e.target.value;
        const numericValue = inputValue.replace(/\D/g, '');
        // Limit the input to a maximum of 16 characters
        if (numericValue.length <= 16 || numericValue === '') {
            setmobile(numericValue);
        }
    };

    return (
        <div>
            <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                    <Typography>{t("click_drag_map_pointer")}</Typography>

                    <Box maxHeight={"100%"}>
                        <div ref={mapRefe} style={{ height: "550px", maxHeight: "100%" }} id="map-1" className=""></div>
                    </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Box>
                        <Typography ml={1} gutterBottom>
                            {t("provide_additional")}
                        </Typography>
                        <Box display={"flex"} flexWrap={"wrap"}>
                            <div>
                                <FormControl className="m-1-w-52" sx={{ mb: 2 }} fullWidth variant="outlined">
                                    <OutlinedInput
                                        id="area"
                                        value={locationName}
                                        onChange={(e) => {
                                            setLocationName(e.target.value)
                                        }}
                                        required
                                        placeholder="House/ Flat/ Block"
                                    />
                                </FormControl>

                                <FormControl className="m-1-w-52" sx={{ mb: 2 }} fullWidth variant="outlined">
                                    <OutlinedInput
                                        id="appartment"
                                        placeholder="Appartment/ Road/ Area"
                                        value={appartment}
                                        onChange={e => setAppartment(e.target.value)}
                                    />
                                </FormControl>
                                <FormControl className="m-1-w-52" sx={{ mb: 2 }} fullWidth variant="outlined">
                                    <OutlinedInput
                                        id="city"
                                        placeholder="City"
                                        value={city}
                                    />
                                </FormControl>
                                <FormControl className="m-1-w-52" sx={{ mb: 2 }} fullWidth>
                                    <OutlinedInput
                                        id="mobile"
                                        placeholder="Mobile no"
                                        value={mobile}
                                        type="tel"
                                        required
                                        inputProps={{ min: 0, maxLength: 16, pattern: '[0-9]*', }}
                                        onChange={handleMobileChang}
                                    />
                                </FormControl>
                            </div>
                            <Box width={"100%"}>
                                <TextField
                                    select
                                    label=""
                                    id="location-type"
                                    className="m-1-w-52"
                                    sx={{ mb: 2, }}
                                    fullWidth
                                    placeholder="type"
                                    value={addressType}
                                    defaultValue="home"
                                    onChange={(e) => setAddressType(e.target.value)}
                                >
                                    <MenuItem value="home" selected={true}>{t("home")}</MenuItem>
                                    <MenuItem value="office">{t("office")}</MenuItem>
                                    <MenuItem value="other">{t("other")}</MenuItem>
                                </TextField>
                                <Box alignItems={"center"} display={"flex"}>
                                    <Checkbox
                                        checked={isDefault}
                                        onClick={e => setIsDefault(!isDefault)}
                                    />
                                    <Typography>{t("default_address")}</Typography>
                                </Box>
                            </Box>
                            <Box width={"52ch"}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={handleSubmit}
                                    className="address-btn"
                                    fullWidth
                                    disabled={loading}
                                >
                                    {loading && (
                                        <FontAwesomeIcon icon={faSpinner} spin />
                                    )}
                                    {t("add_address_button")}
                                </Button>
                            </Box>
                        </Box>

                        <ToastContainer />
                    </Box>
                </Grid>
            </Grid>
        </div>
    );
};

export default AddressForm;
