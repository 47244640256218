/* eslint eqeqeq: 0 */
import { Box, Button, Typography, Dialog, Stack } from "@mui/material";
import OtpInput from "otp-input-react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { auth } from "../../../firebase/config";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { toast } from "react-toastify";
import ClearIcon from "@mui/icons-material/Clear";
import "intl-tel-input/build/css/intlTelInput.css";
import { NavLink } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useTheme } from "@emotion/react";
import * as  api from "../../../API/Api";
import { t } from "i18next";
import { useDispatch, useSelector } from "react-redux";
import { setBookmark } from "../../../redux/Bookmark";
import { updateCartItems } from "../../../redux/cart";
import { setAddress } from "../../../redux/UserAddress"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { setProfile } from "../../../redux/UserData";
import { handleForce } from "../../../redux/Login";
import { handleAuth } from "../../../redux/authentication";
import RegisterUser from "../../Reusable/Profile/RegisterUser";


const Authentication = ({ login, isLogin, setIsloggedIn }) => {

    const settings = useSelector(state => state.Settings).settings;
    let web_settings = settings?.web_settings;
    // let general_settings = settings?.general_settings;
    const mode = web_settings?.demo_mode;
    // let country_code = general_settings?.country_code; 
    //TODO: might use later.
    const initialBackdrop = false;
    const initialshowOtp = false;
    const [otp, setOtp] = useState(mode === "0" ? "" : "123456");
    const [ph, setPh] = useState(mode === "0" ? "" : "919876543210");
    const [loading, setLoading] = useState(false);
    const [showOTP, setShowOTP] = useState(initialshowOtp);
    const theme = useTheme();
    const [isMenuBackdropOpen, setIsMenuBackdropOpen] = useState(initialBackdrop);
    const [addUserDetails, setAddUserDetails] = useState(false);
    const [verification, setVerification] = useState(0);


    const dispatch = useDispatch();


    // function for Capture Code Verification
    function onCaptchVerify() {
        if (!window.recaptchaVerifier) {
            window.recaptchaVerifier = new RecaptchaVerifier(
                "recaptcha-container",
                {
                    size: "invisible",
                    callback: (response) => {
                    },
                    "expired-callback": () => { },
                },
                auth
            );
        }
    }

    const handleClose = () => {
        isLogin(false);
        setShowOTP(initialshowOtp)
        setIsMenuBackdropOpen(initialBackdrop)
        setAddUserDetails(false);
        dispatch(handleForce(true));
    };

    // Function for SignUP
    function onSignup() {
        setLoading(true);
        onCaptchVerify();
        const appVerifier = window.recaptchaVerifier;
        const phoneNumber = "+" + ph;

        signInWithPhoneNumber(auth, phoneNumber, appVerifier)
            .then((confirmationResult) => {
                window.confirmationResult = confirmationResult;
                setLoading(false);
                setShowOTP(true);
                isLogin(false);
                toast.success("OTP sent successfully!");
                return;
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
                window.recaptchaVerifier.render().then((widgetId) => {
                    window.recaptchaVerifier.reset(widgetId);
                });
                toast.error(error.message);
                return;
            });
    }
    let codex = '+' + ph.slice(0, 2);
    const verifyUser = () => {
        api
            .verify_user(ph.slice(2, 12), codex)
            .then((response) => {

                if (response.message_code === "101") {
                    setVerification(1);
                    getToken();
                    return;
                } else if (response.message_code === "102") {
                    setVerification(0);
                    setIsMenuBackdropOpen(true);
                    setAddUserDetails(true);
                    setShowOTP(false);
                    api.get_token(ph.slice(2, 12), codex).then(result => {
                        console.log(result);
                        dispatch(setProfile(result));
                        localStorage.setItem("ContactInfo", ph);
                        localStorage.setItem("Token", result.token);
                    });
                    return;
                } else if (response.message_code === "103") {
                    setVerification(0);
                    toast.error("Your account is Deactivate. Please contact admin for more information");
                    handleClose();
                    return;
                } else {
                    setVerification(1);
                    getToken();
                    return;
                }
            })
            .catch((error) => console.log("error", error));
    };

    //function to getting token when user logged in
    const getToken = () => {
        api
            .get_token(ph.slice(2, 12), codex)
            .then((response) => response)
            .then((result) => {

                localStorage.setItem("ContactInfo", ph);
                localStorage.setItem("isLoggedIn", true);


                isLogin(false);
                setIsloggedIn("true");

                let lat = localStorage.getItem("Lat");
                let lng = localStorage.getItem("Lng");
                localStorage.setItem("Token", result.token);
                dispatch(setProfile(result));
                dispatch(handleAuth(true));
                toast.success(result.message);
                var formdata = new FormData();
                formdata.append("type", "list");
                formdata.append("latitude", lat);
                formdata.append("longitude", lng);

                if (verification === 1) {
                    api.get_bookmarks(formdata).then((bookmarks) => {

                        if (bookmarks.error == false) {
                            let data = bookmarks.data;
                            const markedArray = data.map((item, index) => ({
                                id: index + 1,
                                partner_id: item.partner_id
                            }))
                            dispatch(setBookmark(markedArray));
                        }
                    });


                    api.get_cart()
                        .then((response) => {
                            localStorage.setItem('cartItems', JSON.stringify(response));
                            dispatch(updateCartItems(response.data));
                        })

                    api.get_address()
                        .then((response) => {
                            dispatch(setAddress(response.data));
                        })
                        .catch((error) => console.log("error", error));
                }



            }).then(response => {
                window.location.reload()
            })
            .catch((error) => console.log("error", error));
    };

    //Function for Otp Verification
    function onOTPVerify() {
        if (otp === "") {
            toast.error("Please enter the verification code!");
            return;
        }

        setLoading(true);
        window.confirmationResult
            .confirm(otp)
            .then(async (res) => {
                setLoading(false);
                if (res.user) {
                    verifyUser();
                } else {
                    setLoading(false);
                    toast.error("Invalid verification code. Please try again!");
                }
            })
            .catch((err) => {
                console.log(err);
                setLoading(false);
                toast.error("Failed to verify OTP. Please try again later.");
            });
    }

    const handleKeyPress = (event) => {
        if (event.key === "Enter") {
            event.preventDefault();
            onOTPVerify();
        }
    };

    const formattedPh =
        ph && ph.length >= 2
            ? "+" + ph.substring(0, 2) + " - " + ph.substring(2)
            : "";

    //Resend OTP
    const [resendTimer, setResendTimer] = useState(60);
    const [disableResend, setDisableResend] = useState(false);
    const [intervalId, setIntervalId] = useState(null);

    const onResendOTP = () => {
        onSignup(); // The function to send OTP
        setDisableResend(true);

        const id = setInterval(() => {
            setResendTimer((prevTimer) => prevTimer - 1);
        }, 1000);

        setIntervalId(id); // Store the intervalId in state

        setTimeout(() => {
            clearInterval(id);
            setResendTimer(60);
            setDisableResend(false);
        }, 60000);
    };

    useEffect(() => {
        // Clear the timer interval when the component unmounts
        return () => {
            if (intervalId) {
                clearInterval(intervalId);
            }
        };
    }, [intervalId]);

    return (

        <Box>
            {/* Enter Mobile Number */}

            <Dialog
                sx={{
                    color: "#fff",
                }}
                maxWidth="lg"
                open={login}
            >
                <Box
                    sx={{
                        color: "black",
                        background: theme.palette.background.box,
                        width: { xs: "100%", md: "100%" },
                        textAlign: "center",
                    }}
                >
                    <Box marginLeft={3} maxWidth={"100%"} marginRight={3} marginTop={3} marginBottom={3}>
                        <Box display={isMenuBackdropOpen ? "none" : "flex"} alignItems={"center"}>
                            <Typography marginRight={"auto"} fontSize={20} color={theme.palette.color.textColor}>
                                {t("login")}
                            </Typography>
                            {
                                <ClearIcon
                                    onClick={handleClose}
                                    sx={{ color: theme.palette.icons.icon, cursor: "pointer" }}
                                    fontSize="large"
                                />
                            }
                        </Box>


                        <Typography
                            sx={{
                                color: theme.palette.color.navLink,
                                letterSpacing: 1,
                            }}
                            marginBottom={2}
                            fontSize={22}
                            variant="h6"
                        >
                            {t("welcome")}
                        </Typography>
                        <Typography
                            sx={{ color: theme.palette.color.navLink }}
                            fontSize={14}
                        >
                            {t("send_verification_code")}
                        </Typography>
                        <Box
                            sx={{
                                mt: 5,
                                mb: 2,
                                display: "flex",
                                alignItems: "center",
                                gap: 10,
                            }}
                        >
                            <PhoneInput
                                country={"pk"}
                                value={ph}
                                onChange={setPh}
                                inputStyle={{
                                    width: "100%",
                                }}
                                containerStyle={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    gap: 10,
                                }}
                                onEnterKeyPress={onSignup}
                            />
                        </Box>

                        <Button
                            onClick={onSignup}
                            variant="contained"
                            disableElevation
                            size="large"
                            fullWidth
                            className="border-radius-2 lower-case-btn"
                            sx={{ mb: 5, borderRadius: "8px" }}
                            disabled={loading == true ? true : false}
                        >
                            {loading && (
                                <FontAwesomeIcon icon={faSpinner} spin />
                            )}
                            <span>{t("login_to_continue")}</span>
                        </Button>
                        <Typography color={"gray"} fontSize={15}>
                            {t("agree_to_out")}
                        </Typography>
                        <Typography
                            fontSize={15}
                            display={"flex"}
                            justifyContent={"center"}
                        >
                            <NavLink
                                style={{ color: theme.palette.primary.main, }}
                                to={"/terms-and-conditions"}
                                onClick={() => isLogin(false)}
                            >
                                {t("terms")}
                            </NavLink>
                            &nbsp;
                            <p style={{ color: theme.palette.color.navLink }}>
                                &
                            </p>
                            &nbsp;
                            <NavLink
                                style={{ color: theme.palette.primary.main }}
                                to={"/privacy-policies"}
                                onClick={() => isLogin(false)}
                            >
                                {t("privacy")}
                            </NavLink>
                        </Typography>
                    </Box>
                </Box>
            </Dialog>


            {/* Enter OTP */}

            <Dialog
                sx={{
                    color: "#fff",
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                maxWidth="lg"
                open={showOTP}
            >
                <Box
                    sx={{
                        color: "black",
                        background: theme.palette.background.box,
                        width: { xs: "100%", md: "100%" },
                        textAlign: "center",
                    }}
                >
                    <Box marginLeft={3} maxWidth={"100%"} marginRight={3} marginTop={3} marginBottom={3}>
                        <Box display={isMenuBackdropOpen ? "none" : "flex"} alignItems={"center"}>
                            <Typography marginRight={"auto"} fontSize={20} color={theme.palette.color.textColor}>
                                {t("login")}
                            </Typography>
                            {
                                <ClearIcon
                                    onClick={handleClose}
                                    sx={{ color: theme.palette.icons.icon, cursor: "pointer" }}
                                    fontSize="large"
                                />
                            }
                        </Box>


                        <Box mt={4}>
                            <label
                                htmlFor="otp"
                                className="font-bold text-xl text-white text-center"
                            >
                                <Typography
                                    sx={{
                                        color: theme.palette.color.navLink,
                                        letterSpacing: 1,
                                    }}
                                    variant="h6"
                                    fontSize={22}
                                >
                                    {t("enter_verification_code")}
                                </Typography>
                                <Typography
                                    sx={{
                                        color: theme.palette.color.secondary,
                                        fontSize: 15,
                                        mt: 1,
                                    }}
                                >
                                    {t("sent_verification_code")}
                                </Typography>
                                <Typography
                                    color={theme.palette.primary.main}
                                    fontSize={15}
                                >
                                    {formattedPh}
                                </Typography>
                            </label>
                        </Box>
                        <Box marginTop={5} mb={2} overflow={"auto"} onKeyPress={handleKeyPress}>
                            <OtpInput
                                value={otp}
                                onChange={setOtp}
                                OTPLength={6}
                                otpType="number"
                                disabled={false}
                                autoFocus
                                className="opt-container"
                            ></OtpInput>
                        </Box>


                        <Stack spacing={3}>
                            <Box width={"100%"} display={"flex"} justifyContent={"center"}>
                                <Button
                                    variant="outlined"
                                    sx={{
                                        color: theme.palette.primary.main,
                                        border: "1px solid gray",
                                        textTransform: "none",
                                        borderRadius: "8px",
                                        width: "50%"
                                    }}
                                    onClick={onResendOTP}
                                    disabled={disableResend}
                                >
                                    {disableResend
                                        ?
                                        <Box display={"flex"} alignItems={"center"} gap={1}>
                                            Resend OTP :
                                            <Typography
                                                variant="subtitle2"
                                                color={"#0277FA"}
                                            >
                                                {resendTimer}s
                                            </Typography>
                                        </Box>
                                        : "Resend OTP"}
                                </Button>
                            </Box>

                            <Button onClick={onOTPVerify} disabled={loading == true ? true : false}>
                                <Button
                                    variant="contained"
                                    size="medium"
                                    fullWidth
                                    disabled={loading == true ? true : false}
                                    sx={{ borderRadius: "8px", textTransform: "none", width: "100% !important" }}
                                >
                                    {loading && (
                                        <FontAwesomeIcon icon={faSpinner} spin />
                                    )}
                                    {t("verify_and_process")}
                                </Button>
                            </Button>
                        </Stack>
                        {/* <Box sx={{ mt: 4, mb: 2, width: "100%" }} display={"flex"} flexDirection={"column"} alignItems={"center"}>
                                        </Box> */}
                    </Box>
                </Box>
            </Dialog>


            {/* Edit Profile */}

            <Dialog
                sx={{
                    color: "#fff",
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={addUserDetails}
            >
                <Box
                    sx={{
                        background: theme.palette.background.box,
                        color: "black",
                        width: { xs: "100%", md: "400px" }
                    }}
                >
                    <Box marginLeft={3} maxWidth={"100%"} marginRight={3} marginTop={3} marginBottom={3}>
                        <Box display={isMenuBackdropOpen ? "none" : "flex"} alignItems={"center"}>
                            <Typography marginRight={"auto"} fontSize={20} color={theme.palette.color.textColor}>
                                {t("sign_up")}
                            </Typography>
                            {
                                <ClearIcon
                                    onClick={handleClose}
                                    sx={{ color: theme.palette.icons.icon, cursor: "pointer" }}
                                    fontSize="large"
                                />
                            }
                        </Box>


                        <Box mt={4}>
                            <RegisterUser mobile={ph.slice(2, 12)} />
                        </Box>
                    </Box>
                </Box>
            </Dialog>


            <Box id="recaptcha-container"></Box>
        </Box>


    );
};

export default Authentication;
